import React, { createContext, useState, useContext, ReactNode } from 'react';
import SignClient from '@walletconnect/sign-client'
import { ElectrumCluster, ElectrumTransport } from 'cashscript/node_modules/electrum-cash';
import { ElectrumNetworkProvider, Network } from 'cashscript';
import { ElectrumClient } from "@electrum-cash/network";

interface BlockchainContextType {
  walletConnectSession: any; // Replace 'any' with the appropriate type
  walletConnectInstance: any;
  electrumServer: any;
  electrumCluster: any;
  usersAddress: string;
  connectedChain: string;
  //setBlockchainData: (session: any, instance: any, electrumServer: any, electrumCluster: any, address: string) => void;
  setBlockchainData: (data: { session?: any, instance?: any, electrumServer?: any, electrumCluster?: any, usersAddress?: string, connectedChain?: string }) => void;
}

const BlockchainContext = createContext<BlockchainContextType | undefined>(undefined);

export const useBlockchainContext = () => {
  const context = useContext(BlockchainContext);
  if (!context) throw new Error('useBlockchainContext must be used within a BlockchainProvider');
  return context;
};

interface BlockchainProviderProps {
    children: ReactNode;
  }

export const BlockchainProvider: React.FC<BlockchainProviderProps> = ({ children }) => {
  const [walletConnectSession, setWalletConnectSession] = useState<any>(null);
  const [walletConnectInstance, setWalletConnectInstance] = useState<SignClient>();
  const [electrumServer, setElectrumServer] = useState<ElectrumNetworkProvider>();
  //const [electrumServer, setElectrumServer] = useState<ElectrumClient>();
  const [electrumCluster, setElectrumCluster] = useState<ElectrumCluster>();
  const [usersAddress, setUsersAddress] = useState<string>('');
  const [connectedChain, setConnectedChain] = useState<string>('');
/*
  const setBlockchainData = (session: any, instance: any, electrumServer: any, electrumCluster: any, address: string) => {
    setWalletConnectSession(session);
    setWalletConnectInstance(instance);
    setElectrumServer(electrumServer);
    setElectrumCluster(electrumCluster);
    setUsersAddress(address);
  };
*/
const setBlockchainData = (data: { session?: any, instance?: any, electrumServer?: any, electrumCluster?: any, usersAddress?: string, connectedChain?: string }) => {
    if (data.session !== undefined) {
      setWalletConnectSession(data.session);
    }
    if (data.instance !== undefined) {
      setWalletConnectInstance(data.instance);
    }
    if (data.electrumServer !== undefined) {
      setElectrumServer(data.electrumServer);
    }
    if (data.electrumCluster !== undefined) {
      setElectrumCluster(data.electrumCluster);
    }
    if (data.usersAddress !== undefined) {
      setUsersAddress(data.usersAddress);
    }
    if (data.connectedChain !== undefined) {
      setConnectedChain(data.connectedChain);
    }
  };
  
  return (
    <BlockchainContext.Provider value={{ walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain, setBlockchainData }}>
      {children}
    </BlockchainContext.Provider>
  );
};